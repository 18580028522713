import favorites from './favorites'
import forgotPassword from './forgot-password'
import login from './login'
import logout from './logout'
import playlists from './playlists'
import register from './register'

export default {
    login,
    logout,
    register,
    forgotPassword,
    playlists,
    favorites
}
