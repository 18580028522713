import React from 'react'

import { Form, Input } from 'antd'

import { Container } from './styles'

const Password = ({ label, rules, required, col, ...props }: InputProps): JSX.Element => {
    return (
        <Container>
            <Form.Item
                label={label}
                rules={rules}
                required={required}
                wrapperCol={col}
                {...props}
            >
                <Input.Password />
            </Form.Item>
        </Container>
    )
}

export default Password
