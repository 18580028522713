import { GetServerSidePropsContext } from 'next'

import api from 'utils/api'
import { FILTER_ITEMS_PER_PAGE, FILTER_PAGE, FILTER_SEARCH } from 'utils/api/filters'
import { getTokenFromContext } from 'utils/auth'

type ResolveType = {
    items: [],
    totalItems: number,
}

const paging = (
    endpoint: string,
    params?: {
        pagination?: boolean,
        [FILTER_ITEMS_PER_PAGE]?: number,
        [FILTER_PAGE]?: number,
        [FILTER_SEARCH]?: string,
        [key: string]: string | boolean | number
    },
    context?: GetServerSidePropsContext
): Promise<ResolveType> => new Promise((resolve, reject) => {
    const token = getTokenFromContext(context)
    if (params?.pagination === false) {
        delete params.page
        delete params.itemsPerPage
    }

    api
        .get(
            endpoint,
            {
                token,
                params: params,
                context
            }
        )
        .then(({ data }) => {
            const response = { items: api.getItems(data), totalItems: api.getTotalItems(data) }
            resolve(response)
        })
        .catch(reject)
})

export default paging
