import { AxiosResponse } from 'axios'

import api from 'utils/api'

const remove = (id: string): Promise<void | AxiosResponse> => new Promise((resolve, reject) => {
    api
        .delete(api.endpoints.API_ACCOUNT_FAVORITES_DELETE.replace(':id', id))
        .then(response => resolve(response.data))
        .catch(reject)
})

export default remove
