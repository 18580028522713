import api from 'utils/api'
import { STORAGE_JWT_TOKEN_KEY } from 'utils/auth'
import storage from 'utils/storage'

const login = (
    email: string,
    plainPassword: string
): Promise<JWT | Reject> => new Promise((resolve, reject) => {
    api
        .post(api.endpoints.API_LOGIN, { email, plainPassword })
        .then(({ data }) => {
            const { token } = data
            storage.set(STORAGE_JWT_TOKEN_KEY, token, 'cookies')
            resolve(token)
        })
        .catch(({ response }) => {
            reject(response.data)
        })
})

export default login
