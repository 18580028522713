import * as https from 'https'

import axios, { AxiosResponse } from 'axios'

import { STORAGE_JWT_TOKEN_KEY } from 'utils/auth'
import * as endpoints from 'utils/constants/endpoints'
import { STORAGE_LOCALE_KEY } from 'utils/i18n'
import storage from 'utils/storage'

import { getErrorMessages } from './errors'
import { getLocalFromApiOptions, isServer } from './helpers'

const api = (options) => {
    const token = options?.token || storage.get(STORAGE_JWT_TOKEN_KEY, 'cookies')
    const authHeaders = token ? { Authorization: `Bearer ${token}` } : {}
    const host = options?.context
        ? options.context.req.headers.host
        : (`${window?.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`)

    const axiosInstance = axios.create({
        ...options,
        baseURL: 'https://' + (host === 'localhost:3000' ? 'localhost:8000' : (host + '/api')),
        headers: {
            'Accept-Language': getLocalFromApiOptions(options) || storage.get(STORAGE_LOCALE_KEY, 'cookies'),
            ...authHeaders,
            ...options?.headers
        },
        httpsAgent: new https.Agent({ rejectUnauthorized: false })
    })

    axios.interceptors.request.use(
        function (config) {
            config.headers['Accept-Language'] = getLocalFromApiOptions(options) || storage.get(STORAGE_LOCALE_KEY, 'cookies')
            return config
        },
        function (error) {
            return Promise.reject(error)
        }
    )

    /** Global error handler for client */
    if (!isServer() && (options?.globalError || typeof options?.globalError === 'undefined')) {
        axiosInstance.interceptors.response.use((response) => response, async (error) => {
            const notification = (await import('utils/hook/useNotification')).default()
            const messages = getErrorMessages(error)

            messages.forEach((message) => notification.error(message))

            throw error
        })
    }

    return axiosInstance
}

const get = (endpoint: string, options?: OptionsType) => api(options).get(endpoint)

const post = (endpoint: string, data?: AxiosResponse['data'], options?: OptionsType) => (
    api(options)
        .post(endpoint, data)
)

const put = (endpoint: string, data: AxiosResponse['data'], options?: OptionsType) => (
    api(options)
        .put(endpoint, data)
)

const patch = (endpoint: string, data?: AxiosResponse['data'], options?: OptionsType) => (
    api({
        ...options,
        headers: { ...options?.headers, 'Content-Type': 'application/merge-patch+json' }
    })
        .patch(endpoint, data)
)

const remove = (endpoint: string, options?: OptionsType) => api(options).delete(endpoint)

const getItems = (data: AxiosResponse['data']): [] => data?.['hydra:member']
const getTotalItems = (data: AxiosResponse['data']): number => data?.['hydra:totalItems'] || 0

export default {
    get,
    post,
    put,
    patch,
    delete: remove,
    getItems,
    getTotalItems,
    endpoints
}
