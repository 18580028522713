import React from 'react'

import { Checkbox as AntdCheckbox, Form, Col, Row } from 'antd'

import Ellipsis from '../../text/ellipsis'
import { Container } from './styles'

const Checkbox = ({
    options,
    label,
    rules,
    required,
    col,
    span,
    disabled,
    onChange,
    ...props
}: CheckboxProps): JSX.Element => {
    return (
        <Container>
            <Form.Item
                label={label}
                rules={rules}
                required={required}
                wrapperCol={col}
                {...props}
            >
                <AntdCheckbox.Group
                    onChange={onChange}
                    disabled={disabled}
                    style={{ width: '100%' }}
                >
                    <Row gutter={[0, 6]}>
                        {options.map(option => (
                            <Col span={span} key={option.value}>
                                <AntdCheckbox value={option.value}><Ellipsis>{option.label}</Ellipsis></AntdCheckbox>
                            </Col>
                        ))}
                    </Row>
                </AntdCheckbox.Group>
            </Form.Item>
        </Container>
    )
}

export default Checkbox
