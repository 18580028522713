import styled from '@emotion/styled'

import { colors } from 'settings/theme'

export const Container = styled.div`
  position: relative;

  .ant-input-affix-wrapper {
    border-radius: 25px;  
  }
  
  .ant-form-item-label {
    position: absolute;
    background-color: white;
    line-height: 4px;
    z-index: 2;
    padding: 0 5px;
    overflow: inherit;
    left: 25px;
    top: -3px;
    
    label {
      color: ${colors.primary};
      font-size: 12px;
    }
  }
  
  .ant-input-group-compact {
    display: grid;
    grid-template-columns: 0 auto 1fr;
  }
`
