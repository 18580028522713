import { AxiosResponse } from 'axios'

import api from 'utils/api'

const add = (id: string): Promise<void | AxiosResponse> => new Promise((resolve, reject) => {
    api
        .post(api.endpoints.API_ACCOUNT_FAVORITES_NEW, { training: id })
        .then(response => resolve(response.data))
        .catch(reject)
})

export default add
