import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import withAuthProtection from 'middlewares/withAuthProtection'
import accounts from 'services/accounts'

import useRouter from 'utils/hook/useRouter'

import Layout from 'components/layout'
import Button from 'components/ui/button'
import Form from 'components/ui/form'
import Input from 'components/ui/input'
import Link from 'components/ui/link'

import Icon from './assets/icon.svg'
import { Wrapper, Content, WrapperIcon, FormOptions } from './styles'

export const getServerSideProps = withAuthProtection(null, { authenticated: false })

const Login = (): JSX.Element => {
    const [error, setError] = useState<string | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const { t } = useTranslation()
    const { push, routes } = useRouter()

    const handleSubmit = ({ email, plainPassword }): void => {
        setLoading(true)
        accounts.login(email, plainPassword)
            .then(() => {
                push(routes.home)
            })
            .catch(({ code, message }) => {
                if (code === 401) {
                    setError(t(message))
                }
                setLoading(false)
            })
    }

    return (
        <Layout navigation={false} center>
            <Wrapper>
                <Content>
                    <WrapperIcon>
                        <Icon />
                    </WrapperIcon>
                    <h2>{t('login.title')}</h2>
                    <Form
                        onSubmit={handleSubmit}
                        error={error}
                    >
                        <Input.Text
                            name="email"
                            label={t('form.input.email.label')}
                            rules={[
                                {
                                    required: true,
                                    message: t('form.rules.required')
                                }
                            ]}
                        />
                        <Input.Password
                            name="plainPassword"
                            label={t('form.input.password.label')}
                            rules={[
                                {
                                    required: true,
                                    message: t('form.rules.required')
                                }
                            ]}
                        />
                        <Button type="submit" loading={loading}>{t('login.submit')}</Button>
                    </Form>
                    <FormOptions>
                        <Link to={routes.forgotPassword.resetPassword.request}>{t('login.forgot_password')}</Link>
                    </FormOptions>
                </Content>
            </Wrapper>
        </Layout>
    )
}

export default Login
