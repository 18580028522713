import api from 'utils/api'
import { getViolations } from 'utils/api/helpers'
import { STORAGE_JWT_TOKEN_KEY } from 'utils/auth'
import storage from 'utils/storage'
import { getRandomAvatarColor } from 'utils/user'

type JWT = string

const register = (data): Promise<JWT | Violations> => new Promise((resolve, reject) => {
    api
        .post(api.endpoints.API_ACCOUNTS_REGISTER, { ...data, avatarColor: getRandomAvatarColor() })
        .then(({ data }) => {
            const { token } = data

            storage.set(STORAGE_JWT_TOKEN_KEY, token, 'cookies')

            resolve(token)
        })
        .catch(error => {
            reject(getViolations(error))
        })
})

export default register
