import api from 'utils/api'

const upload = (
    endpoint: string,
    file: FormData,
    options?: OptionsType
): Promise<undefined | {[p: string]: undefined}> => new Promise((resolve, reject) => {
    api
        .post(endpoint, file, options)
        .then(({ data }) => resolve(data))
        .catch(reject)
})

export default upload
