import { GetServerSidePropsContext } from 'next'

import api from 'utils/api'
import { getTokenFromContext } from 'utils/auth'

type ResolveType = {
    items: [],
    totalItems: number,
}

const list = (context?: GetServerSidePropsContext) : Promise<ResolveType> => new Promise((resolve, reject) => {
    const token = getTokenFromContext(context)

    api
        .get(
            api.endpoints.API_ACCOUNT_PLAYLISTS_LIST,
            {
                token,
                context
            }
        )
        .then(({ data }) => {
            const response = { items: api.getItems(data), totalItems: api.getTotalItems(data) }
            resolve(response)
        })
        .catch(reject)
})

export default list
