// accounts

export const API_LOGIN = '/login'
export const API_ACCOUNTS_REGISTER = '/accounts/register'
export const API_ACCOUNTS_INVITATION_VALIDATE = 'accounts/invitation/validate'
export const API_FORGOT_PASSWORD_RESET_PASSWORD_REQUEST = '/accounts/forgot-password/reset-password-request'
export const API_FORGOT_PASSWORD_UPDATE_PASSWORD = '/accounts/forgot-password/update-password'
export const API_FORGOT_PASSWORD_TOKEN_VALIDATE = '/accounts/forgot-password/token-validate'
export const API_ACCOUNTS_LIST = '/accounts'
export const API_ACCOUNTS_COMPANY_LIST = '/accounts/company'
export const API_ACCOUNTS_COMPANY_PROMOTE = '/accounts/:id/company/promote'
export const API_ACCOUNTS_COMPANY_DEMOTE = '/accounts/:id/company/demote'
export const API_ACCOUNTS_ACTIVE = '/accounts/:id/active'
export const API_ACCOUNTS_ADMIN_LIST = '/accounts/admin'
export const API_ACCOUNT_ACCOUNT_SKILLS_LIST = '/account/account-skills'
export const API_ACCOUNT_SKILLS_LIST = '/account-skills'
export const API_ACCOUNT_FAVORITES_LIST = '/account/favorites'
export const API_ACCOUNT_FAVORITES_NEW = '/account/favorites'
export const API_ACCOUNT_FAVORITES_DELETE = '/account/favorites/:id'
export const API_ACCOUNT_PLAYLISTS_LIST = '/account/playlists'
export const API_ACCOUNT_PLAYLISTS_NEW = '/account/playlists'
export const API_ACCOUNT_SKILLS_NEW = '/account/skills'

export const API_COMPANY_ACCOUNT_EXPORT = '/company/accounts/export'
export const API_ADMIN_ACCOUNT_EXPORT = '/admin/accounts/export'

// Certificates
export const API_ACCOUNT_CERTIFICATES_LIST = '/certificates'
export const API_ACCOUNT_CERTIFICATES_EXPORT = '/certificates/export'
export const API_COMPANY_CERTIFICATES_EXPORT = '/company/certificates/export'
export const API_ADMIN_CERTIFICATES_EXPORT = '/admin/certificates/export'

// Community

export const API_COMMUNITY_LIST = '/communities'
export const API_ACCOUNT_COMMUNITY_NEW = '/communities'
export const API_ADMIN_COMMUNITY_NEW = '/admin/communities'
export const API_COMPANY_COMMUNITY_NEW = '/company/communities'
export const API_ADMIN_COMMUNITY_EDIT = '/admin/communities/:id'
export const API_COMPANY_COMMUNITY_EDIT = '/company/communities/:id'
export const API_COMMUNITY_COLLABORATORS_INVITE = '/communities/:id/collaborators/invite'
export const API_COMMUNITY_COLLABORATORS_REMOVE = '/communities/:id/collaborators/remove'
export const API_COMMUNITY_DELETE = '/communities/:id'
export const API_COMMUNITY_VIEW = '/communities/:id'
export const API_COMMUNITY_POSTS_LIST = '/community-posts'
export const API_COMMUNITY_POSTS_COMMENTS_LIST = '/community-posts/comments'
export const API_COMMUNITY_POSTS_COMMENTS_CREATE = '/community-posts/comments'
export const API_COMMUNITY_POSTS_DELETE = '/community-posts/:id'
export const API_COMMUNITY_POSTS_COMMENTS_DELETE = '/community-posts/comments/:id'

export const API_COMMUNITY_TRAINING_POST_NEWT = '/community-training-posts'
export const API_COMMUNITY_TEXT_POST_NEWT = '/community-text-posts'

// Media

export const API_MEDIAS_TRAINING_IMAGES = '/medias/trainings/images'
export const API_MEDIAS_TRAINING_SHEET_CONTENT_FILE = '/medias/training-sheets/content-file'

// Languages

export const API_LANGUAGES = '/languages'

// INVITATIONS

export const API_COMPANY_INVITATIONS_CLEAR = '/company/invitations/clear'
export const API_COMPANY_INVITATIONS_LIST = '/company/invitations'
export const API_COMPANY_INVITATIONS_CREATE = '/company/invitations/nominative'
export const API_ADMIN_INVITATIONS_CREATE = '/admin/invitations/nominative'
export const API_COMPANY_INVITATIONS_DELETE = '/invitations/:id'

// playlists

export const API_PLAYLISTS_ADD_NEW = '/playlists/add'
export const API_PLAYLISTS_VIEW = '/playlists/:id'
export const API_PLAYLISTS_DELETE = '/playlists/:id'
export const API_PLAYLISTS_EDIT = '/playlists/:id'

// Admin --------------------

// Company

export const IRI_COMPANY = '/companies/:id'
export const API_ADMIN_COMPANY_CREATE = '/admin/companies'
export const API_ADMIN_COMPANY_DELETE = '/admin/companies/:id'
export const API_ADMIN_COMPANY_EDIT = '/admin/companies/:id'
export const API_ADMIN_COMPANY_LIST = '/admin/companies'
export const API_ADMIN_COMPANY_VIEW = '/admin/companies/:id'
export const API_ADMIN_COMPANY_INVITATIONS_LIST = '/admin/invitations'
export const API_COMPANIES_SETTINGS_TASKS_VIEW = '/companies/:id/settings/tasks'
export const API_COMPANIES_SETTINGS_TASKS_EDIT = '/companies/:id/settings/tasks'

// Domain

export const API_DOMAIN_LIST = '/domains'
export const API_DOMAIN_MATRIX = '/domains/matrix'
export const API_ADMIN_DOMAIN_CREATE = '/admin/domains'
export const API_ADMIN_DOMAIN_DELETE = '/admin/domains/:id'
export const API_ADMIN_DOMAIN_EDIT = '/admin/domains/:id'
export const API_ADMIN_DOMAIN_LIST = '/admin/domains'
export const API_ADMIN_DOMAIN_VIEW = '/admin/domains/:id'

// Sub domains

export const API_SUB_DOMAIN_LIST = '/sub-domains'
export const API_ADMIN_SUB_DOMAIN_CREATE = '/admin/sub-domains'
export const API_ADMIN_SUB_DOMAIN_DELETE = '/admin/sub-domains/:id'
export const API_ADMIN_SUB_DOMAIN_EDIT = '/admin/sub-domains/:id'
export const API_ADMIN_SUB_DOMAIN_LIST = '/admin/sub-domains'
export const API_ADMIN_SUB_DOMAIN_VIEW = '/admin/sub-domains/:id'

// Emails

export const API_ADMIN_EMAILS_CREATE = '/admin/emails'
export const API_ADMIN_EMAILS_DELETE = '/admin/emails/:id'
export const API_ADMIN_EMAILS_EDIT = '/admin/emails/:id'
export const API_ADMIN_EMAILS_LIST = '/admin/emails'
export const API_ADMIN_EMAILS_VIEW = '/admin/emails/:id'

// Skill

export const API_SKILLS_LIST = '/skills'
export const API_ADMIN_SKILL_CREATE = '/admin/skills'
export const API_ADMIN_SKILL_DELETE = '/admin/skills/:id'
export const API_ADMIN_SKILL_EDIT = '/admin/skills/:id'
export const API_ADMIN_SKILL_LIST = '/admin/skills'
export const API_ADMIN_SKILL_VIEW = '/admin/skills/:id'

// training

export const API_TRAINING_LIST = '/trainings'
export const API_TRAINING_FILTERS_VIEW = '/trainings/:id'
export const API_TRAININGS_ACTIONS_VIEW = '/trainings/:id/actions'
export const API_TRAINING_SUGGESTIONS_LIST = '/trainings/suggestions'
export const API_TRAINING_IN_PROGRESS_LIST = '/trainings/in-progress'
export const API_TRAINING_FINISHED_LIST = '/trainings/finished'
export const API_TRAINING_PINED = '/trainings/pined'
export const API_TRAINING_PROMOTED_LIST = '/trainings/promoted'
export const API_TRAINING_FILTERS_LIST = '/trainings/filters'
export const API_TRAINING_PUBLISH_EDIT = '/trainings/:id/publish'
export const API_TRAINING_ARCHIVE_EDIT = '/trainings/:id/archive'
// training-sheet

export const API_TRAINING_PLAYLISTS_PRIVACY_PRIVATE = '/playlists/:id/privacy/private'
export const API_TRAINING_PLAYLISTS_PRIVACY_COMPANY = '/playlists/:id/privacy/company'
export const API_TRAINING_PLAYLISTS_PRIVACY_GLOBAL = '/playlists/:id/privacy/global'

export const API_TRAINING_PLAYLISTS_REMOVE = '/playlists/:id/remove'

// training-sheet

export const API_TRAINING_SHEET_VIEW = '/training-sheets/:id'

// training-promotions

export const API_TRAINING_PROMOTIONS = '/training-promotions'
export const API_TRAINING_PROMOTIONS_DELETE = '/training-promotions/:id'

// training-sheet admin

export const API_ADMIN_TRAINING_SHEET_CREATE = '/admin/training-sheets'
export const API_ADMIN_TRAINING_SHEET_DELETE = '/admin/training-sheets/:id'
export const API_ADMIN_TRAINING_SHEET_EDIT = '/admin/training-sheets/:id'
export const API_ADMIN_TRAINING_SHEET_LIST = '/admin/training-sheets'
export const API_ADMIN_TRAINING_SHEET_VIEW = '/admin/training-sheets/:id'

export const API_ADMIN_TRAINING_SHEET_TITLE_EDIT = '/admin/training-sheets/:id/title'
export const API_ADMIN_TRAINING_SHEET_DESCRIPTION_EDIT = '/admin/training-sheets/:id/description'
export const API_ADMIN_TRAINING_SHEET_DURATION_EDIT = '/admin/training-sheets/:id/duration'
export const API_ADMIN_TRAINING_SHEET_LANGUAGE_EDIT = '/admin/training-sheets/:id/language'
export const API_ADMIN_TRAINING_SHEET_FORMAT_EDIT = '/admin/training-sheets/:id/format'
export const API_ADMIN_TRAINING_SHEET_IMAGE_EDIT = '/admin/training-sheets/:id/image'
export const API_ADMIN_TRAINING_SHEET_SKILLS_EDIT = '/admin/training-sheets/:id/skills'

// training sheet report
export const API_ADMIN_TRAINING_SHEET_REPORT_VIEW = '/admin/training-sheets/reports/:id'

// training sheet report values
export const API_ADMIN_TRAINING_SHEET_REPORT_VALUE_ENABLE = '/admin/training-sheets/reports/values/:id/enable'

// trainingFormat

export const API_TRAINING_FORMAT_LIST = '/trainings-formats'
export const API_ADMIN_TRAINING_FORMAT_CREATE = '/admin/trainings-formats'
export const API_ADMIN_TRAINING_FORMAT_DELETE = '/admin/trainings-formats/:id'
export const API_ADMIN_TRAINING_FORMAT_EDIT = '/admin/trainings-formats/:id'
export const API_ADMIN_TRAINING_FORMAT_LIST = '/admin/trainings-formats'
export const API_TRAINING_FORMAT_TRAINING_SHEET_LIST = '/trainings-formats/training-sheets'
export const API_ADMIN_TRAINING_FORMAT_VIEW = '/admin/trainings-formats/:id'

// socioProfessionalCategory

export const API_SOCIO_PROFESSIONAL_CATEGORY_LIST = '/socio-professional-categories'
export const API_ADMIN_SOCIO_PROFESSIONAL_CATEGORY_CREATE = '/admin/socio-professional-categories'
export const API_ADMIN_SOCIO_PROFESSIONAL_CATEGORY_DELETE = '/admin/socio-professional-categories/:id'
export const API_ADMIN_SOCIO_PROFESSIONAL_CATEGORY_EDIT = '/admin/socio-professional-categories/:id'
export const API_ADMIN_SOCIO_PROFESSIONAL_CATEGORY_LIST = '/admin/socio-professional-categories'
export const API_ADMIN_SOCIO_PROFESSIONAL_CATEGORY_VIEW = '/admin/socio-professional-categories/:id'

// interest

export const API_INTEREST_LIST = '/interests'
export const API_ADMIN_INTEREST_CREATE = '/admin/interests'
export const API_ADMIN_INTEREST_DELETE = '/admin/interests/:id'
export const API_ADMIN_INTEREST_EDIT = '/admin/interests/:id'
export const API_ADMIN_INTEREST_LIST = '/admin/interests'
export const API_ADMIN_INTEREST_VIEW = '/admin/interests/:id'

// Source Website

export const API_SOURCE_WEBSITE_LIST = '/source-websites'
export const API_ADMIN_SOURCE_WEBSITE_CREATE = '/admin/source-websites'
export const API_ADMIN_SOURCE_WEBSITE_DELETE = '/admin/source-websites/:id'
export const API_ADMIN_SOURCE_WEBSITE_EDIT = '/admin/source-websites/:id'
export const API_ADMIN_SOURCE_WEBSITE_LIST = '/admin/source-websites'
export const API_ADMIN_SOURCE_WEBSITE_VIEW = '/admin/source-websites/:id'

// Source

export const API_ADMIN_SOURCE_CREATE = '/admin/sources'
export const API_ADMIN_SOURCE_DELETE = '/admin/sources/:id'
export const API_ADMIN_SOURCE_EDIT = '/admin/sources/:id'
export const API_ADMIN_SOURCE_SETTINGS_VIEW = '/admin/sources/:id/settings'
export const API_ADMIN_SOURCE_SETTINGS_EDIT = '/admin/sources/:id/settings'
export const API_ADMIN_SOURCE_LIST = '/admin/sources'
export const API_ADMIN_SOURCE_VIEW = '/admin/sources/:id'
export const API_ADMIN_SOURCE_VIEW_REPORT = '/admin/source-reports/:id/report'
export const API_ADMIN_SOURCE_PLAY = '/admin/sources/:id/play'
export const API_ADMIN_SOURCE_STOP = '/admin/sources/:id/stop'

// Source Report

export const API_ADMIN_SOURCE_REPORT_VIEW = '/admin/source/reports/:id'
export const API_ADMIN_SOURCE_REPORT_LIST = '/admin/source-reports'

// Source Mapping

export const API_ADMIN_SOURCE_MAPPING_EDIT = '/admin/sources-mapping/:id/:module'
export const API_ADMIN_SOURCES_MAPPING_DRYRUN = '/admin/sources-mapping/dryrun'
export const API_ADMIN_SOURCE_DEFAULTS_EDIT = '/admin/source-defaults/:id/:module'

// company training-sheet

export const API_COMPANY_TRAINING_SHEET_CREATE = '/company/training-sheets'
export const API_COMPANY_TRAINING_SHEET_DELETE = '/company/training-sheets/:id'
export const API_COMPANY_TRAINING_SHEET_EDIT = '/company/training-sheets/:id'
export const API_COMPANY_TRAINING_SHEET_LIST = '/company/training-sheets'
export const API_COMPANY_TRAINING_SHEET_VIEW = '/company/training-sheets/:id'
export const API_ADMIN_TRAINING_SHEETS__REPORTS_LIST = '/admin/training-sheets/reports'

// session

export const API_SESSION_CREATE = '/sessions'
export const API_SESSION_DELETE = '/sessions/:id'
export const API_SESSION_FINISH_EDIT = '/sessions/:id/finish'
export const API_SESSION_FINISHED_CREATE = '/sessions/finished'
// Tasks

export const API_ADMIN_TASKS_LIST = '/admin/tasks'
export const API_COMPANY_TASKS_LIST = '/company/tasks'
export const API_TASKS_DELETE = '/tasks/:id'
export const API_TASKS_TRAININGS_ISSUES_NEW = '/tasks/trainings/issues'
export const API_TASKS_TRAININGS_NOTE_NEW = '/training-ratings'
export const API_TASKS_TRAINING_SHEETS_SKILLS_NEW = '/tasks/training-sheets/skills'

// evaluations

export const API_ACCOUNT_EVALUATIONS_VIEW = '/account-evaluations/:id'
export const API_ACCOUNT_EVALUATIONS_NEW = '/account-evaluations'
export const API_ACCOUNT_EVALUATIONS_LIST = '/account/evaluations'
export const API_ADMIN_EVALUATIONS_LIST = '/admin/evaluations'
export const API_ADMIN_EVALUATIONS_NEW = '/admin/evaluations'
export const API_ADMIN_EVALUATIONS_EDIT = '/admin/evaluations/:id'
export const API_COMPANY_EVALUATIONS_LIST = '/company/evaluations'
export const API_COMPANY_EVALUATIONS_NEW = '/company/evaluations'
export const API_COMPANY_EVALUATIONS_EDIT = '/company/evaluations/:id'
export const API_EVALUATIONS_DELETE = '/evaluations/:id'
export const API_EVALUATIONS_VIEW = '/evaluations/:id'
export const API_COMPANY_ACCOUNT_EVALUATIONS_EXPORT = '/company/account-evaluations/export'
export const API_ADMIN_ACCOUNT_EVALUATIONS_EXPORT = '/admin/account-evaluations/export'
