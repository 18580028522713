import { GetServerSidePropsContext } from 'next'

import api from 'utils/api'
import { STORAGE_JWT_TOKEN_KEY } from 'utils/auth'
import storage from 'utils/storage'

type PayloadType = {
    token: string | string[]
    plainPassword: string
}

const updatePassword = (payload: PayloadType, context?: GetServerSidePropsContext): Promise<void | Reject> => new Promise((resolve, reject) => {
    api
        .post(api.endpoints.API_FORGOT_PASSWORD_UPDATE_PASSWORD, payload, { context })
        .then(({ data }) => {
            storage.set(STORAGE_JWT_TOKEN_KEY, data?.token, 'cookies')
            resolve()
        })
        .catch(({ response }) => {
            reject(response)
        })
})

export default updatePassword
