import React, { useEffect, useState } from 'react'

import { Form, Select as AntdSelect } from 'antd'
import { useEffectOnce, useUpdateEffect } from 'usehooks-ts'

import paging from 'services/paging'

import { FILTER_PAGE, FILTER_SEARCH } from 'utils/api/filters'

const formatOptions = (items, keys) => items?.map(item => ({
    ...Object.keys(keys)
        .reduce((option, key) => {
            if (typeof keys[key] === 'string') return ({ ...option, [key]: item[keys[key]] })
            if (typeof keys[key] === 'function') return ({ ...option, [key]: keys[key](item) })
        }, {})
}))

const SelectAsync = ({
    label,
    name,
    placeholder,
    data,
    rules,
    required,
    col,
    disabled,
    multiple,
    filterOptions = () => true,
    pagination = true,
    onChange,
    style,
    ...props
}: SelectAsyncProps): JSX.Element => {
    const [options, setOptions] = useState<SelectProps['options']>([])
    const [page, setPage] = useState<number>(1)
    const [loading, setLoading] = useState(false)
    const [totalItems, setTotalItems] = useState<number>(undefined)
    const [query, setQuery] = useState<string>(null)

    const endOfData = totalItems === options.length

    const fetch = () => {
        setLoading(true)
        paging(
            data?.endpoint,
            {
                pagination,
                ...data.params,
                [FILTER_PAGE]: page,
                [FILTER_SEARCH]: query || undefined
            })
            .then(response => {
                const items = formatOptions(response.items, data?.keys)
                setTotalItems(response.totalItems)

                setOptions(page !== 1 ? (options.length ? [...options, ...items] : items) : items)
                pagination === true && setPage(page + 1)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useUpdateEffect(() => {
        if (!disabled) {
            pagination === true && setPage(1)
            fetch()
        }
    }, [query, disabled])

    useEffectOnce(() => {
        if (options.length === 0) {
            fetch()
        }
    })

    useUpdateEffect(() => {
        if (totalItems !== undefined && !disabled) {
            pagination === true && setPage(1)
            fetch()
        }
    }, [data.params])

    const onScroll = async (event) => {
        if (endOfData || pagination === false) return
        const target = event.target
        if (!loading && target.scrollTop + target.offsetHeight + 100 >= target.scrollHeight) {
            target.scrollTo(0, target.scrollHeight)
            fetch()
        }
    }

    const onSearch = (query: string) => {
        pagination === true && setPage(1)
        setQuery(query)
    }

    const searchFilter = (input, option) => option.props.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0

    return (
        <div>
            <Form.Item
                label={label}
                rules={rules}
                required={required}
                wrapperCol={col}
                name={name}
                {...props}
            >
                <AntdSelect
                    onChange={onChange}
                    disabled={disabled}
                    placeholder={placeholder}
                    maxTagCount="responsive"
                    filterOption={searchFilter}
                    showSearch
                    onSearch={onSearch}
                    loading={loading}
                    mode={multiple ? 'multiple' : undefined}
                    onPopupScroll={(!endOfData && pagination) ? onScroll : undefined}
                    style={style}
                >
                    {options.filter(option => filterOptions(option)).map(option => (<AntdSelect.Option value={option.value} key={option.value}>{option.label}</AntdSelect.Option>))}
                </AntdSelect>
            </Form.Item>
        </div>
    )
}

export default SelectAsync
