import i18n from 'react-i18next'

import { SLUG_PATTERN } from 'utils/regex'

import { extractContextMessage, getViolations } from './helpers'

export const getErrorMessages = (error: Error): string[] => {
    const message = error?.response?.data?.['hydra:description']
    const contextMessage = extractContextMessage(message)

    const t = i18n?.getI18n().t

    if (!t) return []

    if (contextMessage !== null) {
        return [t('additional:' + contextMessage.message, contextMessage.context)]
    }

    const violations = getViolations(error)

    if (violations) {
        return violations.map(({ message }) => {
            const contextMessage = extractContextMessage(message)

            if (contextMessage !== null) {
                return t('additional:' + contextMessage.message, contextMessage.context)
            }

            return t('additional:' + message)
        })
    }

    return [t(
        message && SLUG_PATTERN.test(message)
            ? `additional:${message}`
            : 'exceptions.internal_error'
    )]
}
